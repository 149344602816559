import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
//import Container from "@/views/Container.vue";
import home from "@/views/home/home.vue";
import {store} from '../store'
import * as toastr from "toastr";
import i18n from "@/i18n";
const { t } = i18n.global

const routes: Array<RouteRecordRaw> = [

    {
        path: '/',
        name: 'Home',
        // component: ()=>import("../views/home/home.vue")
        component: home,
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/search-result',
        name: 'SearchResult',
        component: () => import("@/views/home/SearchResult.vue"),
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/product-details',
        name: 'ProductDetails',
        component: () => import("@/views/dashboard/product/ProductDetails.vue"),
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/recover-password',
        component: () => import("../views/auth/recoverPassword.vue"),
        name: 'RecoverPassword',
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/signup',
        name: 'Signup',
        component: () => import("../views/auth/signup.vue"),
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import("../views/auth/login.vue"),
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import("../views/auth/UserProfile.vue"),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/products',
        component: () => import("../views/dashboard/dashboard.vue"),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'Products',
                component: () => import("../views/dashboard/product/allProducts.vue")
            },
            {
                path: '/post',
                name: 'PostProduct',
                component: () => import("../views/dashboard/product/addProduct/addProduct.vue")
            },
            {
                path: '/edit/:id',
                name: 'EditProduct',
                component: () => import("../views/dashboard/product/editProduct.vue")
            },
            {
                path: '/favorite-products',
                name: 'FavoriteProducts',
                component: () => import("../views/dashboard/product/FavoriteProduct.vue")
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        //  return { x: 0, y: 0 };
        return {top: 0, behavior: 'smooth'}
    }
})

router.beforeEach((to, from, next) => {

    const isLoggedIn = store.getters['auth/loggedIn'];
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth && !isLoggedIn) {
        toastr.info(t('loginPage.authMessage'))
        next({name: 'Login'});
    } else next()
})


export default router
