
import {computed, defineComponent, onMounted} from "vue";
import {useStore} from "vuex";
import {key} from "@/store/index";
import {useI18n} from 'vue-i18n'


export default defineComponent({
  name: "topNav",
  setup() {
    const store = useStore(key)
    const {locale} = useI18n()

    function signOut() {
      return store.dispatch('auth/signOut')
    }

    function switchLanguage(context) {
      locale.value = context;
      // ($('.dropdown-toggle') as any).dropdown('toggle')
    }

    const loggedIn = computed(() => store.getters['auth/loggedIn'])
    const user = computed(() => store.getters['user/get'])
    const profilePicture = computed(() => store.getters['auth/getPicture'])

    onMounted(() => {
      var e: any = $(window).width();
      $(window).height();

      function t(i, t) {
        var e = $(i + " > a");
        e.append('<span class="icon-plus"></span>'), e.on("click", function (e) {
          e.preventDefault(), $(this).parents(i).find(t).slideToggle().parents(i).siblings().find(t).slideUp()
        })
      }

      e < 991 && (t(".has_dropdown", ".dropdown"), t(".has_megamenu", ".dropdown_megamenu")), $(".search_trigger").on("click", function () {
        $(this).toggleClass("icon-magnifier icon-close"), $(this).parent(".search_module").children(".search_area").toggleClass("active")
      }), $(".close_menu").on("click", function () {
        $(this).parent(".offcanvas-menu").addClass("closed")
      }), $(".menu_icon").on("click", function () {
        $(this).siblings(".offcanvas-menu").removeClass("closed")
      }), $(".filter__menu_icon").on("click", function () {
        $(".filter_dropdown").toggleClass("active")
      });


      $(".menu_icon").on("click", function () {
        $(this).siblings(".offcanvas-menu").removeClass("closed")
      });
      $(".close_menu").on("click", function () {
        $(this).parent(".offcanvas-menu").addClass("closed")
      })
    })
    return {
      user,
      profilePicture,
      signOut,
      switchLanguage,
      loggedIn
    }
  },
})
