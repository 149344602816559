/* eslint-disable */
import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
import auth from "@/store/modules/auth";
import user from "@/store/modules/user";
import country from "@/store/modules/country";
import product from "@/store/modules/product";
import region from "@/store/modules/region";
import category from "@/store/modules/category";
import plan from "@/store/modules/plan";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

// define your typings for the store state
export interface State {
  count: number
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  plugins: [createPersistedState({
    key: "tukumbo",
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    }
  })],
  state: {
    count: 0
  },
  modules: {
    auth,
    user,
    country,
    product,
    region,
    category,
    plan
  }
})