/* eslint-disable */
import {initializeApp} from "firebase/app"
import {getFirestore} from "firebase/firestore"
import {getAuth} from "firebase/auth";
import {getStorage, ref, StorageReference} from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBpmHv5rMEJIdurkSZf8pgJH5JCukeWx9A",
    authDomain: "tukumbo-staging.firebaseapp.com",
    projectId: "tukumbo-staging",
    storageBucket: "tukumbo-staging.appspot.com",
    messagingSenderId: "658438122842",
    appId: "1:658438122842:web:aed6cbbbc917b2ae049763"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const productImageRef = (path: string): StorageReference => ref(storage, `products/${path}`);
export default app;