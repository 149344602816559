/* eslint-disable */
import {filter} from "fuzzaldrin";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {key} from "@/store";

export default function useProduct() {
    const store = useStore(key)
    const router = useRouter()

    function get_product_count_by_category(categoryID: string, products: any[] = []) {
        const filteredProduct = products.filter(product => product.category._id === categoryID)
        return filteredProduct.length
    }

    function get_product_count_by_sub_category(subCategoryID: string, products: any[] = []) {
        const filteredProduct = products.filter(product => product.subCategory._id === subCategoryID)
        return filteredProduct.length
    }

    function get_product_categories(category: any[]= [], products: any[] = []) {
        const productIDs = products.map(product => product.category._id)
        return  category.filter(category => productIDs.includes(category.id))
    }

    function get_sub_categories(categoryID: string, subCategories: any[]) {
        return subCategories.filter(subCategory => subCategory.categoryID === categoryID)

    }

    function get_product_result(products: any[], searchText: string, keyName: string) {
        return filter(products, searchText, {key: keyName})
    }

    async function addToFavorites(id: string) {
        await store.dispatch('product/addProductToFavorite', id)
    }

    async function removeFromFavorites(id: string) {
        await store.dispatch('product/deleteProductFromFavorites', id)
    }

    function productIsFavorite(favoriteProducts: any[], productID: string) {
        const product = favoriteProducts.filter(favorite => favorite.product.id === productID)
        return product.length > 0
    }

    async function gotoDetailPage(product) {
        store.commit('product/setSingleProduct', product)
        await router.push({name: 'ProductDetails'})
    }

    return {
        get_product_count_by_category,
        get_product_count_by_sub_category,
        get_product_categories,
        get_sub_categories,
        get_product_result,
        productIsFavorite,
        addToFavorites,
        removeFromFavorites,
        gotoDetailPage
    }
}