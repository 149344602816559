/* eslint-disable */
import {Module} from "vuex";
import {Axios_API_instance} from "@/config/config";
import {categoryState, categoryContext} from "@/interfaces/store/category";
import Category from "@/models/category";
import SubCategory from "@/models/subCategory";


export default {
    namespaced: true,
    state: {
        categories: [] as Category[],
        subCategories: [] as Category[],
        error: false,
        message: "",
    },
    getters: {
        getError: state => state.error,
        getMessage: state => state.message,
        getCategories: state => state.categories,
        getSubCategories: state => state.subCategories
    },
    mutations: {
        setError: (state, payload) => state.error=payload,
        setMessage: (state, payload) => state.message=payload,
        setCategories: (state, payload) => state.categories = payload,
        setSubCategories: (state, payload) => state.subCategories = payload,
    },
    actions: {
        async getByID({commit}, {id}: categoryContext) {
            if (id) {
                try{
                    const category = new Category();
                    const response = (await Axios_API_instance.get(`category/${id}`))
                    category.fromMongo(response.data);
                    commit('setError', false);
                    return category;
                }catch (e){
                    commit('setError', true);
                    commit('setMessage', e.message)
                }
            }else{
                commit('setError', true);
                commit('setMessage', "provide a category id")
            }
        },
        async getSubByID({commit}, {id}: categoryContext) {
            if (id) {
                try{
                    const subCategory = new SubCategory();
                    const response = (await Axios_API_instance.get(`sub-category/${id}`))
                    subCategory.fromMongo(response.data);
                    commit('setError', false);
                    return subCategory;
                }catch (e){
                    commit('setError', true);
                    commit('setMessage', e.message)
                }
            }else{
                commit('setError', true);
                commit('setMessage', "provide a sub category id")
            }
        },
        async fetch({commit}) {
            try{
                const response = (await Axios_API_instance.get('category'))
                const categories = response.data.map((category: Record<string, any>)=>new Category().fromMongo(category));
                commit("setCategories", categories);
                commit('setError', false);
            }catch (e){
                commit('setError', true);
                return commit('setMessage', e.message)
            }

        },
        async fetchSub({commit}) {
            try{
                const response = (await Axios_API_instance.get(`sub-category`))
                const categories = response.data.map((category: Record<string, any>)=>new SubCategory().fromMongo(category));
                commit("setSubCategories", categories);
                commit('setError', false);
            }catch (e){
                commit('setError', true);
                return commit('setMessage', e.message)
            }

        },
    }
} as Module<categoryState, any>