class Category implements CategoryModel{
    createdAt = new Date();
    createdBy = "";
    imageURL = "";
    name = "";
    updatedAt = new Date();
    id = "";

    constructor() {
        //
    }

    fromMongo(category: Record<string, any>): Category{
        this.id = category._id;
        this.createdAt = category.createdAt;
        this.createdBy = category.createdBy;
        this.imageURL = category.imageURL;
        this.name = category.name;
        this.updatedAt = category.updatedAt;
        return this;
    }

    toJSON(): Record<string, any>{
        return {
            id: this.id,
            name: this.name,
            imageURL: this.imageURL,
            createdBy: this.createdBy,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt
        }
    }

}

interface CategoryModel{
    id: string,
    name: string,
    imageURL: string
    createdBy: string,
    createdAt: Date,
    updatedAt: Date
}

export default Category