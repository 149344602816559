import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {store, key} from './store'
//import jQuery from 'jquery'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
//window.jQuery = jQuery
import 'owl.carousel';
import 'owl.carousel/dist/owl.carousel.min';
import 'owl.carousel/dist/assets/owl.carousel.css';
//import '@popperjs/core'

import 'toastr/build/toastr.css'

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


import i18n from './i18n'


createApp(App)
    .use(VueLoading, {loader: 'dots', color: '#6e4ff6'})
    .use(i18n)
    .use(store, key)
    .use(router)
    .mount('#app')

