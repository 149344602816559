/* eslint-disable */
import {Module} from "vuex";
import User from "@/models/user";
import {userState} from "@/interfaces/store/user";
import {Axios_API_instance} from "@/config/config";
import i18n from "@/i18n";
const { t } = i18n.global
export default {
    namespaced: true,
    state: {
        error: false,
        message: '',
        user: {} as User
    },
    getters: {
        getError: state => state.error,
        getMessage: state => state.message,
        get: state => state.user
    },
    mutations: {
        setError: (state, payload) => state.error = payload,
        setMessage: (state, payload) => state.message = payload,
        set: (state, payload) => state.user = payload
    },
    actions: {

        async updateProfile({commit}, user) {
            let response;
            try {
                const mongoResponse = (await Axios_API_instance.patch(`user/${user.id}`, Object.assign({}, user)))

                const _user = new User().fromMongo(mongoResponse.data);
                commit('set', _user);
                response = {status: true, message: t('profilePage.updateMessage')}
            } catch (e) {
                console.log(e.message)
                response = {status: false, message: e.message}

            }
            return Promise.resolve(response)
        }
    }
} as Module<userState, any>;