export default {
  "homePage": {
    "search": {
      "displayMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy and sell anything for the best price"])},
      "searchButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Now"])},
      "searchHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i am looking for"])}
    },
    "nav": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
      "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])}
    },
    "filterProduct": {
      "filterBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter product by"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select"])}
    },
    "products": {
      "fetchingProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetching product please wait"])},
      "noProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Product to display"])},
      "ads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ads"])}
    },
    "categories": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
      "noSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sub-categories yet"])}
    },
    "favorite": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to favorite"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from favorite"])}
    },
    "ctx": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready To Join Our Marketplace!"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grow your marketing and be happy with your online business"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Us Today"])}
    },
    "footer": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved."])}
    },
    "script": {
      "emptySearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a product name to search"])},
      "noProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Product matched your search"])}
    }
  },
  "loginPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "welcomeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Back"])},
    "welcomeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can sign in with your email"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "passwordHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password"])},
    "remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember Me"])},
    "loginButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Now"])},
    "googleButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Google"])},
    "appleButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Apple"])},
    "lossPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lost your password?"])},
    "lossPasswordText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recover your account here"])},
    "noAccountTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])},
    "noAccountText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signup here"])},
    "extraInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra information is needed from you."])},
    "validationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All required fields should not be left blank"])},
    "inValidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Email address"])},
    "inValidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password field is empty"])},
    "loginMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are logged in"])},
    "authMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please login to continue"])}
  },
  "signUpPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "welcomeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Your Account"])},
    "welcomeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill the following fields with appropriate information to register a new Tukumbo account."])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "firstNameHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first name"])},
    "lastNameHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last name"])},
    "emailHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address"])},
    "passwordHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your password"])},
    "confirmPasswordHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm your password"])},
    "selectCountryHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Country"])},
    "registerButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Now"])},
    "googleButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with Google"])},
    "appleButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sign in with Apple"])},
    "accountExistText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
    "accountExistLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "proceedButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed"])},
    "validationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All required fields should not be left blank"])},
    "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 character length, must contain uppercase and lowercase letters"])},
    "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
    "signUpMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration successful"])},
    "accountExistMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An account already exists with this email"])},
    "accountNotExistMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This account does not exist"])},
    "userDetailsNotExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User details does not exist on Database"])},
    "userDetailsFetchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to fetch user details"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["failed to save user details to Database"])}
  },
  "postProductPage": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post a Product"])},
    "stepOneTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Description"])},
    "stepTwoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Images"])},
    "stepThreeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Information"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "formOne": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name & Description"])},
      "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name"])},
      "productNameHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your product name"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-Category"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Description"])},
      "nameValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name field is required"])},
      "priceValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product price field is required"])},
      "catValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product category field is required"])},
      "subCatValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product sub-category field is required"])},
      "descriptionValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product description field is required"])}
    },
    "formTwo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload product images"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
      "imageValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload at least, a single image for the product"])}
    },
    "formThree": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Information"])},
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
      "paymentPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Plan"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
      "subRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-Region"])},
      "negotiable": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negotiable?"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])}
      },
      "validationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All required fields need to be filled"])}
    }
  },
  "profilePage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Profile"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "nameValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name and last name fields are required"])},
    "phoneValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number field is required"])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile update successful"])}
  },
  "userProductPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All User Products"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit product"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View product"])},
    "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in"])},
    "noProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not uploaded any item yet"])}
  },
  "favoriteProductPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite Products"])},
    "buttonHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from favorite"])},
    "noFavorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No favorite products"])}
  },
  "productDetailsPage": {
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make An Offer"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply within a few hours"])},
    "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on Tokumbo"])},
    "showContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Contact"])},
    "startChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Chat"])},
    "posted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posted"])},
    "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["views"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "negotiable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price is Negotiable"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])}
    },
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Description"])},
    "noPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No phone number specified"])},
    "script": {
      "loggedInError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please log in to view owner phone number"])}
    }
  },
  "recoverPasswordPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover Password"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A verification link will be sent to your email. Kindly click the link to reset your password."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "emailHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Recovery Email"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A link has been sent to your email address"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This account does not exist"])}
  },
  "editProductPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit a Product"])},
    "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name & Description"])},
    "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-category"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Description"])},
    "imageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update product images"])},
    "imageButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "otherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Information"])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "paymentPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Plan"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "subRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-Region"])},
    "negotiable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negotiable?"])},
    "priceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Information"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "loadErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to load product information."])}
  },
  "viewProductPage": {
    "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-category"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "itemImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item images"])},
    "noImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item has no image"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "paymentPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Plan"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated at"])},
    "negotiable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is this item Negotiable"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers can negotiate the price of this item"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The price of this item can not be negotiated"])}
    },
    "visible": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item is visible to the public"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item is not visible to the public"])}
    },
    "posted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posted on"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  },
  "itemImagePage": {
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])}
  }
}