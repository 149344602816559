import Product from "@/models/product";
class Favorite implements FavoriteModel{
    product = new Product();
    createdAt = new Date();
    createdBy = "";
    updatedAt = new Date();
    id = "";

    constructor() {
        //
    }

    fromMongo(favorite: Record<string, any>): Favorite{
        this.id = favorite._id;
        this.createdAt = favorite.createdAt;
        this.createdBy = favorite.createdBy;
        this.product = new Product().fromMongo(favorite.product) ;
        this.updatedAt = favorite.updatedAt;
        return this;
    }

    toJSON(): Record<string, any>{
        return {
            createdAt: this.createdAt,
            createdBy: this.createdBy,
            product: this.product,
            updatedAt: this.updatedAt
        }
    }

}

interface FavoriteModel {
    id: string;
    product: Product;
    createdBy: string;
    createdAt: Date;
    updatedAt: Date
}

export default Favorite