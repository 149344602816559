class Plan implements PlanModel{
    countryID = "";
    price = 0;
    createdAt = new Date();
    createdBy = "";
    name = "";
    updatedAt = new Date();
    id = "";

    constructor() {
        //
    }

    fromMongo(country: Record<string, any>): Plan{
        this.id = country._id;
        this.countryID = country.countryID;
        this.createdAt = country.createdAt;
        this.createdBy = country.createdBy;
        this.price = country.price;
        this.name = country.name;
        this.updatedAt = country.updatedAt;
        return this;
    }

    toJSON(): Record<string, any>{
        return {
            countryID: this.countryID,
            createdAt: this.createdAt,
            createdBy: this.createdBy,
            price: this.price,
            name: this.name,
            updatedAt: this.updatedAt
        }
    }

}

interface PlanModel {
    id: string;
    name: string;
    countryID: string;
    price: number;
    createdBy: string;
    createdAt: Date;
    updatedAt: Date
}

export default Plan