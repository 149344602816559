/* eslint-disable */
import {Module} from "vuex";
import {Axios_API_instance} from "@/config/config";
import {planContext, planState} from "@/interfaces/store/plan";
import Plan from "@/models/plan";
import {regionContext} from "@/interfaces/store/region";
import Region from "@/models/region";


export default {
    namespaced: true,
    state: {
        plans: [] as Plan[],
        error: false,
        message: "",
    },
    getters: {
        getError: state => state.error,
        getMessage: state => state.message,
        plans: state => state.plans
    },
    mutations: {
        setError: (state, payload) => state.error=payload,
        setMessage: (state, payload) => state.message=payload,
        setPlans: (state, payload) => state.plans = payload
    },
    actions: {
        async getByID({commit}, {id}: planContext) {
            if (id) {
                try{
                    const plan = new Plan();
                    const response = (await Axios_API_instance.get(`plan/${id}`))
                    plan.fromMongo(response.data);
                    commit('setError', false);
                    return plan;
                }catch (e){
                    commit('setError', true);
                    commit('setMessage', e.message)
                }
            }else{
                commit('setError', true);
                commit('setMessage', "provide a plan id")
            }
        },
        async fetch({commit}) {
            try{
                const response = (await Axios_API_instance.get('plan'))
                const plan = response.data.map((plan: Record<string, any>)=>new Plan().fromMongo(plan));
                commit("setPlans", plan);
                commit('setError', false);
            }catch (e){
                commit('setError', true);
                return commit('setMessage', e.message)
            }

        }
    }
} as Module<planState, any>