class SubRegion implements SubRegionModel{
    regionID = "";
    createdAt = new Date();
    createdBy = "";
    id = "";
    name = "";
    updatedAt = new Date();

    constructor() {
        //
    }

    fromMongo(region: Record<string, any>): SubRegion{
        this.regionID = region.regionID;
        this.createdAt = region.createdAt;
        this.createdBy = region.createdBy;
        this.id = region._id;
        this.name = region.name;
        this.updatedAt = region.updatedAt;
        return this;
    }

    toJSON(): Record<string, any>{
        return {
            id: this.id,
            regionID: this.regionID,
            name: this.name,
            createdBy: this.createdBy,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt
        }
    }
}

interface SubRegionModel{
    id: string,
    regionID: string,
    name: string,
    createdBy: string,
    createdAt: Date,
    updatedAt: Date
}

export default SubRegion;