import axios from "axios";

class ExtAPIService{
    async getIP(): Promise<string>{
        const response = await axios.get("https://geolocation-db.com/json/");
        return response.data['IPv4'];
    }
    async getNetworkTime(): Promise<Date> {
        const ip = await this.getIP();
        const response = await $.getJSON(`https://www.timeapi.io/api/Time/current/ip?ipAddress=${ip}`);
        return new Date(response.dateTime);
    }
}

export default ExtAPIService