class SubCategory implements SubCategoryModel{
    createdAt = new Date();
    createdBy = "";
    imageURL = "";
    name = "";
    categoryID = "";
    updatedAt = new Date();
    id = "";

    constructor() {
        //
    }

    fromMongo(category: Record<string, any>): SubCategory{
        this.id = category._id;
        this.createdAt = category.createdAt;
        this.createdBy = category.createdBy;
        this.imageURL = category.imageURL;
        this.name = category.name;
        this.categoryID = category.categoryID;
        this.updatedAt = category.updatedAt;
        return this;
    }

    toJSON(): Record<string, any>{
        return {
            id: this.id,
            name: this.name,
            imageURL: this.imageURL,
            createdBy: this.createdBy,
            createdAt: this.createdAt,
            categoryID: this.categoryID,
            updatedAt: this.updatedAt
        }
    }

}

interface SubCategoryModel{
    id: string;
    name: string;
    imageURL: string;
    createdBy: string;
    categoryID: string;
    createdAt: Date;
    updatedAt: Date;
}

export default SubCategory