/* eslint-disable */
import {Module} from "vuex";
import User from "@/models/user";
import ExtAPIService from "@/services/ExtAPIService";
import {Axios_API_instance} from "@/config/config";
import {authContext, authState} from "@/interfaces/store/auth";
import i18n from "@/i18n";

const {t} = i18n.global
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    sendPasswordResetEmail,
    OAuthProvider,
    GoogleAuthProvider,
    signInWithPopup
} from "firebase/auth";
import {auth} from "@/config/firebase";
import router from "@/router";

export default {
    namespaced: true,
    state: {
        error: false,
        message: '',
        loggedIn: false,
        pictureURL: ''
    },
    getters: {
        getError: state => state.error,
        getMessage: state => state.message,
        loggedIn: (state): boolean => {

            onAuthStateChanged(auth, user => {
                state.loggedIn = Boolean(user)
            })

            return state.loggedIn
        },
        getPicture: state => state.pictureURL
    },
    mutations: {
        setError: (state, payload) => state.error = payload,
        setMessage: (state, payload) => state.message = payload,
        setPicture: (state, payload) => state.pictureURL = payload,
        reset(state) {
            state.error = false;
            state.message = '';
            state.pictureURL = ''
        }
    },
    actions: {
        async signUpWithEmailAndPassword({commit, dispatch}, {user}) {
            commit('setPicture', null)
            try {
                user.ipAddress = await new ExtAPIService().getIP();
                //  user.createdAt = user.updatedAt = new Date();
                await auth.signOut();
                await createUserWithEmailAndPassword(auth, user.email, user.password);

                await Axios_API_instance.post('user/save-user-to-db', Object.assign({}, user))
                const response = await dispatch('completeAuth');
                if (response.status === 200) {
                    const _user = new User().fromMongo(response.data);
                    commit('user/set', _user, {root: true});
                    return Promise.resolve({status: true})
                } else throw new Error(i18n.global.t('signUpPage.saveError'))
            } catch (e) {
                if (e.response) {
                    if (Array.isArray(e.response.data.message)) {
                        return Promise.reject({message: e.response.data.message[0]})
                    } else if (typeof e.response.data.message === 'string') {
                        return Promise.reject({message: e.response.data.message})
                    }
                } else return Promise.reject({message: e.message})
            }
        },
        async signInWithGoogle({commit, dispatch}) {
            try {
                await auth.signOut();
                const user = new User();
                const provider = new GoogleAuthProvider();
                const userCredential = await signInWithPopup(auth, provider);
                commit('setPicture', userCredential.user.photoURL)
                const response = await dispatch('completeAuth')
                if (response.status === 204) {
                    user.isEmailVerified = userCredential.user.emailVerified;
                    user.email = userCredential.user.email as string;
                    if (userCredential.user.displayName) {
                        const [firstname, lastname] = userCredential.user.displayName.split(" ");
                        user.firstName = firstname;
                        user.lastName = lastname ? lastname : ''
                    }
                    user.phoneNumber = userCredential.user.phoneNumber ? userCredential.user.phoneNumber : '';
                    user.ipAddress = await new ExtAPIService().getIP();
                    user.userType = 'user';
                    return Promise.resolve({
                        isNewUser: true,
                        user,
                        status: false,
                        message: t('signUpPage.userDetailsNotExist')
                    })

                } else if (response.status === 200) {
                    const _user = new User().fromMongo(response.data);
                    commit('user/set', _user, {root: true});
                    return Promise.resolve({isNewUser: false, status: true, message: t('loginPage.loginMessage')})
                }

            } catch (e) {
                console.log(e.message);
                await auth.signOut();
                return Promise.reject({message: e.message})

            }
        },

        async completeExtraStep({commit, dispatch}, {user}: authContext) {
            try {
                await Axios_API_instance.post('user/save-user-to-db', Object.assign({}, user))
                const response = await dispatch('completeAuth');
                return Promise.resolve(response)
                // else throw new Error(t('signUpPage.saveError'))
            } catch (e) {
               // return Promise.reject({message: e.message})
                if(e.response){
                    if(Array.isArray(e.response.data.message)){
                        return Promise.reject({message: e.response.data.message[0]})
                    } else if(typeof  e.response.data.message === 'string'){
                        return Promise.reject({message: e.response.data.message})
                    }
                }
                else return Promise.reject({message: e.message})
            }

        },
        async signInWithApple({commit, dispatch}) {
            try {
                await auth.signOut();
                const user = new User();
                const provider = new OAuthProvider('apple.com');
                const userCredential = await signInWithPopup(auth, provider);
                commit('setPicture', userCredential.user.photoURL);
                let response = await dispatch('completeAuth')
                if (response.status === 204) {
                    user.isEmailVerified = userCredential.user.emailVerified;
                    user.email = userCredential.user.email as string;
                    if (userCredential.user.displayName) {
                        const [firstname, lastname] = userCredential.user.displayName.split(" ");
                        user.firstName = firstname;
                        user.lastName = lastname ? lastname : '';
                    }
                    user.phoneNumber = userCredential.user.phoneNumber ? userCredential.user.phoneNumber : '';
                    user.ipAddress = await new ExtAPIService().getIP();

                    return Promise.resolve({
                        isNewUser: true,
                        user,
                        status: false,
                        message: t('signUpPage.userDetailsNotExist')
                    })

                } else if (response.status === 200) {
                    const _user = new User().fromMongo(response.data);
                    commit('user/set', _user, {root: true});
                    return Promise.resolve({isNewUser: false, status: true, message: t('loginPage.loginMessage')})
                }
            } catch (e) {
                console.log(e.message);
                await auth.signOut();
                return Promise.reject({message: e.message})

            }

        },
        async signInWithEmailAndPassword({commit, dispatch}, {email, password}: Record<string, string>) {
            commit('setPicture', null)
            try {

                await signInWithEmailAndPassword(auth, email, password);

                const response = await dispatch('completeAuth');
                return Promise.resolve(response)

            } catch (e) {
                if (e.code === "auth/user-not-found") {
                    return Promise.reject({message: t('signUpPage.accountNotExistMessage')})
                } else {
                    return Promise.reject({message: e.message})
                }

            }
        },

        async sendPasswordResetLink({commit}, {email}: authContext) {
            try {
                await sendPasswordResetEmail(auth, email!);

                return Promise.resolve({message: t('recoverPasswordPage.successMessage'), status: true})
            } catch (e) {
                console.log(JSON.stringify(e))
                if (e.code === "auth/user-not-found") {
                    return Promise.reject({message: t('recoverPasswordPage.errorMessage')})
                } else {
                    return Promise.reject({message: e.message})
                }


            }
        },
        async completeAuth({commit}) {
            try {
                const mongoResponse = (await Axios_API_instance.get('user/fetch-user-from-db'))
                return Promise.resolve(mongoResponse);
            } catch (e) {
                /* console.log(e.message)
                 return Promise.reject({message: e.message})*/
                if (e.response) {
                    if (Array.isArray(e.response.data.message)) {
                        return Promise.reject({message: e.response.data.message[0]})
                    } else if (typeof e.response.data.message === 'string') {
                        return Promise.reject({message: e.response.data.message})
                    }
                } else return Promise.reject({message: e.message})
            }
        },

        async signOut({commit}) {
            try {
                await auth.signOut()
            } catch (e) {
                console.log(e.message)
            } finally {
                commit('reset')
                await router.push({name: 'Login'})
            }
        }
    }
} as Module<authState, any>;