import {createI18n, LocaleMessages, VueMessageType} from 'vue-i18n'
import {availableLanguages} from "@/utils/util";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages: LocaleMessages<VueMessageType> = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key).default
        }
    })
    return messages
}

//Detect user's language via web browser preferred language
//const availableLanguages = ["en", "fr"];
const locale =
    [
        ...(window.navigator.languages || []),
        window.navigator.language,
        window.navigator["browserLanguage"],
        window.navigator["userLanguage"],
        window.navigator["systemLanguage"]
    ]
        .filter(Boolean)
        .map(language => language.substr(0, 2))
        .find(language => availableLanguages.includes(language)) || "en";

export default createI18n({
    legacy: false,
    globalInjection: true,
    locale: locale,
    fallbackLocale: 'en',
    messages: loadLocaleMessages()
})
