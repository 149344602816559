
import {defineComponent, reactive, toRefs, onMounted} from "vue";

export default defineComponent({
  name: 'bottomNav',
  setup(){
    const state = reactive({
    })

    onMounted(() => {
      var o = $(".go_top").hide();
      $(window).on("scroll", function() {

        117 < ($(document).scrollTop() as number)  ? o.fadeIn(400) : o.fadeOut(400)
      }), o.on("click", function() {
        return $("html, body").animate({
          scrollTop: 0
        }, 800), !1
      })
    })

    return{  ...toRefs(state)}
  },

});
