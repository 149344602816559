/* eslint-disable */
import {Module} from "vuex";
import Product from "@/models/product";
import Favorite from "@/models/favorite";
import {Axios_API_instance} from "@/config/config";
import {productContext, productState} from "@/interfaces/store/product";

export default {
    namespaced: true,
    state: {
        error: false,
        loading1: false,
        loading2: false,
        message: '',
        products: [] as Product[],
        singleProduct: {} as Product,
        favoriteProducts: [],
        searchResultProduct: [] as Product[],
        userProducts: [] as Product[]
    },
    getters: {
        getError: state => state.error,
        getLoading: state => state.loading1,
        getLoading2: state => state.loading2,
        getMessage: state => state.message,
        getProducts: state => state.products,
        getSingleProduct: state => state.singleProduct,
        getFavoriteProducts: state => state.favoriteProducts,
        getSearchedProduct: state => state.searchResultProduct,
        userProducts: state => state.userProducts,
        imgUploadPath: (state, getters, rootState, rootGetters) => {
            return `products/${rootGetters['user/get'].id}}`;
        }
    },
    mutations: {
        setError: (state, payload) => state.error = payload,
        setLoading: (state, payload) => state.loading1 = payload,
        setLoading2: (state, payload) => state.loading2 = payload,
        setMessage: (state, payload) => state.message = payload,
        setProducts: (state, payload) => state.products = payload,
        setSingleProduct: (state, payload) => state.singleProduct = payload,
        setFavoriteProducts: (state, payload) => state.favoriteProducts = payload,
        setSearchedProduct: (state, payload) => state.searchResultProduct = payload,
        userProducts: (state, payload) => state.userProducts = payload,
    },
    actions: {
        async get({commit}, {id}: productContext) {
            try {
                const response = (await Axios_API_instance.get(`product/${id}`))
                const product = new Product().fromMongo(response.data);
                commit('setSingleProduct', product)
                commit('setError', false);
                return Promise.resolve(product)
            } catch (e) {
                commit('setError', true);
                return commit('setMessage', e.message)
            }
        },
        async add({commit}, {product}: productContext) {
            try {
                (await Axios_API_instance.post('product', Object.assign({}, product)))
                commit('setError', false);
                commit('setMessage', "Product uploaded");
            } catch (e) {
                const err = JSON.parse(JSON.stringify(e));
                commit('setError', true);
                commit('setMessage', e.message);
            }
        },
        async update({commit, dispatch}, {product}) {
            product.createdBy = typeof product.createdBy === 'string' ? product.createdBy : product.createdBy.id;
            product.region = typeof product.region === 'string' ? product.region : product.region._id;
            product.subRegion = typeof product.subRegion === 'string' ? product.subRegion : product.subRegion._id;
            product.category = typeof product.category === 'string' ? product.category : product.category._id;
            product.subCategory = typeof product.subCategory === 'string' ? product.subCategory : product.subCategory._id;
            product.paymentPlan = typeof product.paymentPlan === 'string' ? product.paymentPlan : product.paymentPlan._id;

            try {
                (await Axios_API_instance.patch(`product/${product?.id}`, Object.assign({}, product)))
                commit('setError', false);
                return commit('setMessage', "Updated successfully")
            } catch (e) {
                commit('setError', true);
                if (e.response) {
                    if (Array.isArray(e.response.data.message)) {
                        return commit('setMessage', e.response.data.message[0])
                    } else if (typeof e.response.data.message === 'string') {
                        return commit('setMessage', e.response.data.message)
                    }
                } else return commit('setMessage', e.message)
            }
            },
        async fetch({commit}) {
            commit('setLoading2', true)
            try {
                const response = (await Axios_API_instance.get('product'))
                const products = response.data.map((product: Record<string, any>) => new Product().fromMongo(product));
                commit('setLoading2', false)
                commit('setProducts', products);
                commit('setError', false);
            } catch (e) {
                commit('setLoading2', false)
                commit('setError', true);
                return commit('setMessage', e.message)
            }
        },
        async fetchByUser({commit}) {
            try {
                const response = (await Axios_API_instance.get('product/user-products'))
                const products = response.data.map((product: Record<string, any>) => new Product().fromMongo(product));
                commit('userProducts', products);
                commit('setError', false);
            } catch (e) {
                commit('setError', true);
                if (e.response) {
                    if (Array.isArray(e.response.data.message)) {
                        return commit('setMessage', e.response.data.message[0])
                    } else if (typeof e.response.data.message === 'string') {
                        return commit('setMessage', e.response.data.message)
                    }
                } else return commit('setMessage', e.message)
            }
        },
        async searchProductByName({commit}, productName) {
            commit('setLoading', true)
            try {
                const response = (await Axios_API_instance.get(`product/search-product-by-name/${productName}`))
                const products = response.data.map((product: Record<string, any>) => new Product().fromMongo(product));
                commit('setLoading', false)
                commit('setSearchedProduct', products);
                return Promise.resolve({ data: products})
            } catch (e) {
                commit('setLoading', false)
                if (e.response) {
                    if (Array.isArray(e.response.data.message)) {
                        return Promise.reject({message: e.response.data.message[0]})
                    } else if (typeof e.response.data.message === 'string') {
                        return Promise.reject({message: e.response.data.message})
                    }
                } else return Promise.reject({message: e.message})
            }
        },
        async searchProductByRegion({commit}, regionID) {
            commit('setLoading2', true)
            try {
                const response = (await Axios_API_instance.get(`product/region/${regionID}`))
                const products = response.data.map((product: Record<string, any>) => new Product().fromMongo(product));
                commit('setLoading2', false)
                commit('setProducts', products);
                commit('setError', false);
            } catch (e) {
                console.log(e.message);
                commit('setLoading2', false)
                commit('setError', true);
                return commit('setMessage', e.message)
            }
        },
        async searchProductByCategory({commit}, categoryID) {
            commit('setLoading2', true)
            try {
                const response = (await Axios_API_instance.get(`product/category/${categoryID}`))
                const products = response.data.map((product: Record<string, any>) => new Product().fromMongo(product));
                commit('setLoading2', false)
                commit('setProducts', products);
                commit('setError', false);
            } catch (e) {
                console.log(e.message);
                commit('setLoading2', false)
                commit('setError', true);
                return commit('setMessage', e.message)
            }
        },
        async addProductToFavorite({commit, rootGetters, dispatch}, id) {
            try {
                (await Axios_API_instance.post('favorite', {
                    product: id,
                    createdBy: rootGetters['user/get'].id
                }))
                await dispatch('fetchFavoriteProducts')
                commit('setError', false);
            } catch (e) {
                console.log(e.message);
                commit('setError', true);
                return commit('setMessage', e.message)
            }
        },
        async deleteProductFromFavorites({commit, dispatch}, id) {
            try {
                (await Axios_API_instance.delete(`favorite/product-id/${id}`))
                await dispatch('fetchFavoriteProducts')
                commit('setError', false);
            } catch (e) {
                console.log(e.message);
                commit('setError', true);
                return commit('setMessage', e.message)
            }
        },
        async fetchFavoriteProducts({commit}) {
            try {
                const response = (await Axios_API_instance.get('favorite/user-favorites'))
                const products = response.data.map((product: Record<string, any>) => new Favorite().fromMongo(product));
                commit('setFavoriteProducts', products);
                commit('setError', false);
            } catch (e) {
                console.log(e.message);
                commit('setError', true);
                return commit('setMessage', e.message)
            }
        },

        async updateProductView({dispatch, commit, rootGetters, state}) {
            try {
                if (rootGetters['auth/loggedIn']) {
                    await Axios_API_instance.patch(`product/update-view/${state.singleProduct.id}`)
                    await dispatch('get', {id: state.singleProduct.id})
                    commit('setError', false);
                }
            } catch (e) {
                console.log(e.message);
                commit('setError', true);
                return commit('setMessage', e.message)
            }
        },
        async updateProductViewedOwnerPhone({dispatch, commit, rootGetters}, productID) {
            try {
                if (rootGetters['auth/loggedIn']) {
                    await Axios_API_instance.patch(`product/update-viewed-owner-phone/${productID}`)
                    // await dispatch('get', {id: productID})
                    commit('setError', false);
                }
            } catch (e) {
                console.log(e.message);
                commit('setError', true);
                return commit('setMessage', e.message)
            }
        }
    }
} as Module<productState, any>;