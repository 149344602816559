class Region implements RegionModel{
    countryID = "";
    createdAt = new Date();
    createdBy = "";
    id = "";
    name = "";
    updatedAt = new Date();

    constructor() {
        //
    }

    fromMongo(region: Record<string, any>): Region{
        this.countryID = region.countryID;
        this.createdAt = region.createdAt;
        this.createdBy = region.createdBy;
        this.id = region._id;
        this.name = region.name;
        this.updatedAt = region.updatedAt;
        return this;
    }

    toJSON(): Record<string, any>{
        return {
            id: this.id,
            countryID: this.countryID,
            name: this.name,
            createdBy: this.createdBy,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt
        }
    }
}

interface RegionModel{
    id: string,
    countryID: string,
    name: string,
    createdBy: string,
    createdAt: Date,
    updatedAt: Date
}

export default Region;