import axios, {AxiosRequestConfig} from "axios";
import {auth} from "@/config/firebase";
import {onAuthStateChanged} from 'firebase/auth'

const Axios_Config = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    headers: {
        'Content-Type': 'application/json',
    }
})

// Add a request interceptor
Axios_Config.interceptors.request.use(async function (config: AxiosRequestConfig) {

    const token = await new Promise(resolve => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                resolve(user.getIdToken(true))
            } else resolve('')
        })
    })

    config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
    }


    return config

}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export const Axios_API_instance = Axios_Config


