export default {
  "homePage": {
    "search": {
      "displayMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achetez et vendez n'importe quoi au meilleur prix"])},
      "searchButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher maintenant"])},
      "searchHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je cherche"])}
    },
    "nav": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maison"])},
      "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendre"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoris"])},
      "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des produits"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
      "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])}
    },
    "filterProduct": {
      "filterBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtrer le produit par"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sélectionner"])}
    },
    "products": {
      "fetchingProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération du produit, veuillez patienter"])},
      "noProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun produit à afficher"])},
      "ads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annonce"])}
    },
    "categories": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
      "noSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de sous-catégories"])}
    },
    "favorite": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter aux Favoris"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer des favoris"])}
    },
    "ctx": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêt à rejoindre notre marché!"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développez votre marketing et soyez satisfait de votre activité en ligne"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez-nous aujourd'hui"])}
    },
    "footer": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les droits sont réservés."])}
    },
    "script": {
      "emptySearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez un nom de produit à rechercher"])},
      "noProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun produit ne correspond à votre recherche"])}
    }
  },
  "loginPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "welcomeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content de te revoir"])},
    "welcomeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez vous connecter avec votre email"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "emailHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre adresse email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "passwordHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez votre mot de passe"])},
    "remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souviens-toi de moi"])},
    "loginButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecte-toi maintenant"])},
    "googleButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer avec Google"])},
    "appleButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer avec Apple"])},
    "lossPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe perdu?"])},
    "lossPasswordText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["récupérer votre compte ici"])},
    "noAccountTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'ai pas de compte?"])},
    "noAccountText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inscrivez-vous ici"])},
    "extraInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des informations supplémentaires sont nécessaires de votre part."])},
    "validationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les champs obligatoires ne doivent pas être laissés vides"])},
    "inValidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail invalide"])},
    "inValidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ du mot de passe est vide"])},
    "loginMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes connecté"])},
    "authMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de vous connecter pour continuer"])}
  },
  "signUpPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
    "welcomeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre compte"])},
    "welcomeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir les champs suivants avec les informations appropriées pour enregistrer un nouveau compte Tukumbo."])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De campagne"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez le mot de passe"])},
    "firstNameHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "lastNameHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
    "emailHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre adresse email"])},
    "passwordHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez votre mot de passe"])},
    "confirmPasswordHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmer votre mot de passe"])},
    "selectCountryHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez le pays"])},
    "registerButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire maintenant"])},
    "googleButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous avec Google"])},
    "appleButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se connecter avec Apple"])},
    "accountExistText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un compte?"])},
    "accountExistLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "proceedButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procéder"])},
    "validationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les champs obligatoires ne doivent pas être laissés vides"])},
    "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit comporter au moins 8 caractères, doit contenir des lettres majuscules et minuscules"])},
    "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas"])},
    "signUpMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inscription réussi"])},
    "accountExistMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un compte existe déjà avec cet email"])},
    "accountNotExistMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce compte n'existe pas"])},
    "userDetailsNotExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les détails de l'utilisateur n'existent pas dans la base de données"])},
    "userDetailsFetchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la récupération des détails de l'utilisateur"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["échec de l'enregistrement des détails de l'utilisateur dans la base de données"])}
  },
  "postProductPage": {
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier un produit"])},
    "stepOneTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du produit"])},
    "stepTwoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images du produit"])},
    "stepThreeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les autres informations"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous faire parvenir"])},
    "formOne": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et description du produit"])},
      "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du produit"])},
      "productNameHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le nom de votre produit"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
      "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-catégorie"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du produit"])},
      "nameValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ du nom du produit est obligatoire"])},
      "priceValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ prix du produit est obligatoire"])},
      "catValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ de la catégorie de produit est obligatoire"])},
      "subCatValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ de sous-catégorie de produit est obligatoire"])},
      "descriptionValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ de description du produit est obligatoire"])}
    },
    "formTwo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger des images de produits"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
      "imageValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez au moins une seule image pour le produit"])}
    },
    "formThree": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les autres informations"])},
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])},
      "paymentPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan de paiement"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
      "subRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-région"])},
      "negotiable": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Négociable?"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non"])}
      },
      "validationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les champs obligatoires doivent être remplis"])}
    }
  },
  "profilePage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le profil"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De campagne"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sauvegarder"])},
    "nameValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les champs prénom et nom sont obligatoires"])},
    "phoneValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le champ Numéro de téléphone est obligatoire"])},
    "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour du profil réussie"])}
  },
  "userProductPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les produits utilisateur"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le produit"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le produit"])},
    "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dans"])},
    "noProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore téléchargé d'article"])}
  },
  "favoriteProductPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits favoris"])},
    "buttonHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer des favoris"])},
    "noFavorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun produit préféré"])}
  },
  "productDetailsPage": {
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire une offre"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse en quelques heures"])},
    "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur Tokumbo"])},
    "showContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le contact"])},
    "startChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer la discussion"])},
    "posted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publié"])},
    "views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vues"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "negotiable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prix est négociable"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non"])}
    },
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du produit"])},
    "noPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun numéro de téléphone spécifié"])},
    "script": {
      "loggedInError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vous connecter pour voir le numéro de téléphone du propriétaire"])}
    }
  },
  "recoverPasswordPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupérer mot de passe"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un lien de vérification sera envoyé à votre adresse e-mail. Veuillez cliquer sur le lien pour réinitialiser votre mot de passe."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
    "emailHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre adresse email"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un e-mail de récupération"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un lien a été envoyé à votre adresse e-mail"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce compte n'existe pas"])}
  },
  "editProductPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier un produit"])},
    "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et description du produit"])},
    "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du produit"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
    "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-catégorie"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du produit"])},
    "imageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour les images des produits"])},
    "imageButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["effacer"])},
    "otherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les autres informations"])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])},
    "paymentPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan de paiement"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
    "subRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-région"])},
    "negotiable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Négociable?"])},
    "priceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur les prix"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prix"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sauvegarder"])},
    "loadErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de charger les informations sur le produit."])}
  },
  "viewProductPage": {
    "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dans"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prix"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
    "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-catégorie"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])},
    "itemImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images de l'article"])},
    "noImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet article n'a pas d'image"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La description"])},
    "paymentPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan de paiement"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis à jour à"])},
    "negotiable": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet article est-il négociable"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les clients peuvent négocier le prix de cet article"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prix de cet article ne peut pas être négocié"])}
    },
    "visible": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilité"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet élément est visible au public"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet élément n'est pas visible au public"])}
    },
    "posted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posté sur"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])}
  },
  "itemImagePage": {
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter"])}
  }
}