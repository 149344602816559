/* eslint-disable */
import {Module} from "vuex";
import {Axios_API_instance} from "@/config/config";
import {regionState, regionContext} from "@/interfaces/store/region";
import Region from "@/models/region";
import SubRegion from "@/models/subRegion";


export default {
    namespaced: true,
    state: {
        regions: [] as Region[],
        subRegions: [] as SubRegion[],
        error: false,
        message: "",
    },
    getters: {
        getError: state => state.error,
        getMessage: state => state.message,
        getRegions: state => state.regions,
        getSubRegions: state => state.subRegions
    },
    mutations: {
        setError: (state, payload) => state.error=payload,
        setMessage: (state, payload) => state.message=payload,
        setRegions: (state, payload) => state.regions = payload,
        setSubRegions: (state, payload) => state.subRegions = payload,
    },
    actions: {
        async getByID({commit}, {id}: regionContext) {
            if (id) {
                try{
                    const region = new Region();
                    const response = (await Axios_API_instance.get(`region/${id}`))
                    region.fromMongo(response.data);
                    commit('setError', false);
                    return region;
                }catch (e){
                    commit('setError', true);
                    commit('setMessage', e.message)
                }
            }else{
                commit('setError', true);
                commit('setMessage', "provide a region id")
            }
        },
        async getSubByID({commit}, {id}: regionContext) {
            if (id) {
                try{
                    const region = new SubRegion();
                    const response = (await Axios_API_instance.get(`sub-region/${id}`))
                    region.fromMongo(response.data);
                    commit('setError', false);
                    return region;
                }catch (e){
                    commit('setError', true);
                    commit('setMessage', e.message)
                }
            }else{
                commit('setError', true);
                commit('setMessage', "provide a subregion id")
            }
        },
        async fetch({commit}) {
            try{
                const response = (await Axios_API_instance.get(`region`))
                const regions = response.data.map((region: Record<string, any>)=>new Region().fromMongo(region));
                commit("setRegions", regions);
                commit('setError', false);
            }catch (e){
                commit('setError', true);
                return commit('setMessage', e.message)
            }

        },
        async fetchSub({commit}) {
            try{
                const response = (await Axios_API_instance.get(`sub-region`))
                const subRegions = response.data.map((subRegion: Record<string, any>)=>new SubRegion().fromMongo(subRegion));
                commit("setSubRegions", subRegions);
                commit('setError', false);
            }catch (e){
                commit('setError', true);
                return commit('setMessage', e.message)
            }
        },
    }
} as Module<regionState, any>