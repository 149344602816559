/* eslint-disable */
import {Module} from "vuex";
import {Axios_API_instance} from "@/config/config";
import Country from "@/models/country";
import {countryContext, countryState} from "@/interfaces/store/country";


export default {
    namespaced: true,
    state: {
        countries: [] as Country[],
        error: false,
        message: "",
    },
    getters: {
        getError: state => state.error,
        getMessage: state => state.message,
        getCountries: state => state.countries
    },
    mutations: {
        setError: (state, payload) => state.error=payload,
        setMessage: (state, payload) => state.message=payload,
        setCountries: (state, payload) => state.countries = payload
    },
    actions: {
        async getByID({commit}, {id}: countryContext) {
            if (id) {
                try{
                    const country = new Country();
                    const response = (await Axios_API_instance.get(`country/${id}`))
                    country.fromMongo(response.data);
                    commit('setError', false);
                    return country;
                }catch (e){
                    commit('setError', true);
                    commit('setMessage', e.message)
                }
            }else{
                commit('setError', true);
                commit('setMessage', "provide a country id")
            }
        },
        async fetch({commit}) {
            try{
                const response = (await Axios_API_instance.get('country'))
                const countries = response.data.map((country: Record<string, any>)=>new Country().fromMongo(country));
                commit("setCountries", countries);
                commit('setError', false);
            }catch (e){
                commit('setError', true);
                return commit('setMessage', e.message)
            }

        }
    }
} as Module<countryState, any>