import passwordValidator from "password-validator";
// Create a schema
const schema = new passwordValidator();
export const availableLanguages = ["en", "fr"];

// Add properties to it
schema
    .is().min(8)                                    // Minimum length 8
    .has().uppercase()                              // Must have uppercase letters
    .has().lowercase()                              // Must have lowercase letters
    .has().not().spaces()


export function validateEmail(mail: string): boolean {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)
}

export function validatePassword(password:string) {
    return schema.validate(password)
}

export function formatCurrency(code: string, currency: string, amount: number) {
    return new Intl.NumberFormat(code, {
        style: 'currency',
        currency: currency
    }).format(amount)
}