import dayjs from "dayjs";

export default class User implements UserModel {
    countryID = "";
    createdAt = dayjs().format();
    deviceTokens = [];
    email = "";
    firstName = "";
    ipAddress = "";
    lastName = "";
    password = "";
    phoneNumber = "";
    updatedAt = dayjs().format();
    isEmailVerified = false;
    userType = 'user';
    id = "";

    constructor() {
        this.createdAt = this.updatedAt = dayjs().format();
    }

    get hasRequiredFields(): boolean {
        const excludedFields = ['_id', 'ipAddress', 'createdAt', 'deviceTokens', 'updatedAt', 'isEmailVerified']
        return Object.entries(this).every((value: any) => {
            if (excludedFields.includes(value[0]) || typeof value[1] === 'function') return true
            return value[1].length > 0;
        })
    }

    fromMongo(user: Record<string, any>): User {
        this.id = user.id;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.email = user.email;
        this.password = user.password;
        this.countryID = user.countryID;
        this.phoneNumber = user.phoneNumber;
        this.ipAddress = user.ipAddress;
        this.deviceTokens = user.deviceTokens;
        this.createdAt = user.createdAt;
        this.updatedAt = user.updatedAt;
        this.isEmailVerified = user.isEmailVerified;
        this.userType = user.userType;
        return this;
    }

    toJSON(): Record<string, any> {
        return {
            id: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            password: this.password,
            countryID: this.countryID,
            phoneNumber: this.phoneNumber,
            ipAddress: this.ipAddress,
            deviceTokens: this.deviceTokens,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            isEmailVerified: this.isEmailVerified,
            userType: this.userType
        }
    }

}

interface UserModel {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    countryID: string,
    phoneNumber: string,
    ipAddress: string,
    deviceTokens: string[],
    isEmailVerified: boolean,
    userType: string,
    createdAt: Date | string,
    updatedAt: Date | string
}
