class Country implements CountryModel{
    countryCode = "";
    countryISO = "";
    createdAt = new Date();
    createdBy = "";
    currency = "";
    name = "";
    updatedAt = new Date();
    id = "";

    constructor() {
        //
    }

    fromMongo(country: Record<string, any>): Country{
        this.id = country._id;
        this.countryCode = country.countryCode;
        this.countryISO = country.countryISO;
        this.createdAt = country.createdAt;
        this.createdBy = country.createdBy;
        this.currency = country.currency;
        this.name = country.name;
        this.updatedAt = country.updatedAt;
        return this;
    }

    toJSON(): Record<string, any>{
        return {
            countryCode: this.countryCode,
            countryISO: this.countryISO,
            createdAt: this.createdAt,
            createdBy: this.createdBy,
            currency: this.currency,
            name: this.name,
            updatedAt: this.updatedAt
        }
    }

}

interface CountryModel {
    id: string,
    name: string,
    countryISO: string,
    countryCode: string,
    currency: string,
    createdBy: string,
    createdAt: Date,
    updatedAt: Date
}

export default Country