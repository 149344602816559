
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import {useStore} from 'vuex'
import {key} from "@/store/index";
import {useRouter} from "vue-router";
import * as bgImage from "@/assets/img/hero-image01.png";
import topNav from "@/components/home/topNav.vue";
import bottomNav from "@/components/home/bottomNav.vue";
import useProduct from "@/composables/useProduct";
import Autocomplete from '@/utils/plugins/VueAutoComplete/Autocomplete.vue'
import * as toastr from "toastr";
import {useI18n} from 'vue-i18n';
import {useLoading} from 'vue-loading-overlay'


export default defineComponent({
  name: 'Home',
  components: {
    topNav,
    bottomNav,
    Autocomplete
  },
  setup() {
    const $loading: any = useLoading()
    const productRef = ref(null);
    const loggedIn = computed(() => store.getters['auth/loggedIn'])
    const loading = computed(() => store.getters['product/getLoading2'])
    const router = useRouter()
    const store = useStore(key)
    store.dispatch('product/fetch')
    store.dispatch('category/fetch')
    store.dispatch('category/fetchSub')
    store.dispatch('region/fetch')
    //  store.dispatch('region/fetchSub')
    if (loggedIn.value) {
      store.dispatch('product/fetchFavoriteProducts')
    }
    const state = reactive({
      bgImage,
      filterBy: '',
      filterID: '',
      items: [] as any[],
      searchResult: [],
      loading: null as any
    })
    const {
      get_product_count_by_category,
      get_product_count_by_sub_category,
      get_sub_categories,
      get_product_result,
      productIsFavorite,
      addToFavorites,
      removeFromFavorites,
      gotoDetailPage
    } = useProduct()
    const {t} = useI18n()

    const regions = computed(() => store.getters['region/getRegions'])
    const categories = computed(() => store.getters['category/getCategories'])
    const subCategories = computed(() => store.getters['category/getSubCategories'])
    const favoriteProducts = computed(() => store.getters['product/getFavoriteProducts'])

    const filteredResult = computed(() => {
      let result;
      switch (state.filterBy) {
        case 'region':
          result = regions.value
          break;
        case 'category':
          result = categories.value
          break;
        default:
          result = [];
      }
      return result
    })

    const products = computed(() => store.getters['product/getProducts'])

    watch(() => loading.value, (newValue) => {
      if(newValue){
       state.loading = $loading.show({container: productRef.value, loader: 'dots', color: '#6e4ff6'})
      }else state.loading.hide()
    }, {immediate: true})

    watch(() => state.filterID, async (newValue) => {
     // const loader =  $loading.show({container: productRef.value, loader: 'dots'})
      try {
        if (state.filterBy === 'category' && newValue !== '') {
          await store.dispatch('product/searchProductByCategory', newValue)
        } else if (state.filterBy === 'region' && newValue !== '') {
          await store.dispatch('product/searchProductByRegion', newValue)
        } else {
          await store.dispatch('product/fetch', newValue)
        }
      } catch (e) {
        console.log(e.message)
      }finally {
       // loader.hide()
      }

    })

    function getItems(context) {
      state.items = get_product_result(store.getters['product/getProducts'], context, 'name');
    }

    async function selectedProduct(context) {
      // console.log(context)
      let searchedProduct = get_product_result(store.getters['product/getProducts'], context, 'name');
      if (searchedProduct.length > 0) {
        await store.commit('product/setSearchedProduct', searchedProduct)
        await router.push({name: 'SearchResult'})
      } else return false
    }

    async function searchForProduct(context) {
      if (context === '') {
        toastr.info(t('homePage.script.emptySearch'))
        return false
      }
      try {
        let response = await store.dispatch('product/searchProductByName', context);
        if (response.data.length > 0) {
          await router.push({name: 'SearchResult'})
        } else return toastr.info(t('homePage.script.noProduct'))

      } catch (e) {
        await toastr.info(e.message)
      }
    }


    return {
      getItems,
      searchForProduct,
      selectedProduct,
      products,
      favoriteProducts,
      categories,
      subCategories,
      filteredResult,
      addToFavorites,
      removeFromFavorites,
      productIsFavorite,
      get_product_count_by_category,
      get_product_count_by_sub_category,
      get_sub_categories,
      gotoDetailPage,
      loggedIn,
      productRef,
      ...toRefs(state)
    }
  }
})
