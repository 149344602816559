import dayjs from "dayjs";

class Product implements ProductModel{
    category = "";
    subCategory = "";
    companyName = "";
    createdAt = dayjs().format();
    createdBy = "";
    description = "";
    images: string[] = [];
    isNegotiable = false;
    isVisible = true;
    name = "";
    paymentPlan = "";
    price = 0;
    region = "";
    subRegion = "";
    updatedAt = dayjs().format();
    viewedBy: string[] = [];
    viewedOwnerPhoneNumber = [];
    id = "";

    constructor() {
        //
    }

    fromMongo(product: Record<string, any>): Product{
        this.id = product._id;
        this.category = product.category;
        this.subCategory = product.subCategory;
        this.companyName = product.companyName;
        this.createdAt = product.createdAt;
        this.createdBy = product.createdBy;
        this.description = product.description;
        this.images = product.images;
        this.isNegotiable = product.isNegotiable;
        this.isVisible = product.isVisible;
        this.name = product.name;
        this.paymentPlan = product.paymentPlan;
        this.price = product.price;
        this.region = product.region;
        this.subRegion = product.subRegion;
        this.updatedAt = product.updatedAt;
        this.viewedBy = product.viewedBy;
        this.viewedOwnerPhoneNumber = product.viewedOwnerPhoneNumber;
        return this;
    }

    toJSON(): Record<string, any>{
        return {
            id: this.id,
            name: this.name,
            category: this.category, //this should be an object of category and subCategories
            subCategory: this.subCategory,
            region: this.region, //this should be an object of region and subRegion
            subRegion: this.subRegion,
            images: this.images,
            companyName: this.companyName,
            description: this.description,
            price: this.price,
            isNegotiable: this.isNegotiable,
            paymentPlan: this.paymentPlan,
            isVisible: this.isVisible,
            viewedBy: this.viewedBy, // Array of users object
            viewedOwnerPhoneNumber: this.viewedOwnerPhoneNumber, // Array of users object
            createdBy: this.createdBy,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt
        }
    }

}

interface ProductModel{
    id: string,
    name: string,
    category: string, //this should be an object of category and subCategories
    subCategory: string,
    region: string, //this should be an object of region and subRegion
    subRegion: string,
    images: string[],
    companyName: string,
    description: string,
    price: number,
    isNegotiable: boolean,
    paymentPlan: string,
    isVisible: boolean,
    viewedBy: string[], // Array of users object
    viewedOwnerPhoneNumber: string[],// Array of users object
    createdBy: string,
    createdAt: Date | string,
    updatedAt: Date | string
}

export default Product